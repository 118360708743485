import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import ErrorHeader from "../components/error/header.js";
import "../components/error/error.scss";
import TrackingTags from "../components/tracking_tags/index.js";

const Hata = () => (
  <>
    <Helmet
      title="Bir hata oluştu! | Havada Kalmasın"
      meta={[
        {
          name: "description",
          content: `Üzgünüz, böyle bir sayfa yok ya da bir sorun oluştu...`,
        },
        { name: "robots", content: "noindex,nofollow" },
      ]}
    >
      <html lang="tr" />
      <TrackingTags />
    </Helmet>
    <Layout>
      <ErrorHeader />
      <div className="error">
        <div className="container">
          <img
            src="/images/error.png"
            alt="Hata"
          ></img>
          <h1>Bir hata oluştu!</h1>
          <h2>Üzgünüz, böyle bir sayfa yok ya da bir sorun oluştu…</h2>
          <Link to="/">Anasayfaya Dön</Link>
        </div>
      </div>
    </Layout>
  </>
);

export default Hata;
