import React from "react";

import "./header.scss";

const ErrorHeader = () => (
  <header className="error-header">
    <div className="container">
      <div className="row middle-xs">
        <div className="logo-container col-md-6 col-sm-6 col-xs-6">
          <a id="logo" className="thanks-header__logo" href="/">
            <img
              className="logo"
              alt="Havada Kalmasın | Greenpeace"
              src="/images/gp-logo.svg"
            />
          </a>
        </div>
        <div className="support-container col-md-6 col-sm-6 col-xs-6">
          <a
            className="thanks-header__support"
            href="https://destek.greenpeace.org/havadakalmasin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            DESTEK OL
          </a>
        </div>
      </div>
    </div>
  </header>
);

export default ErrorHeader;
